import React, { useState } from 'react';
import Slider from 'react-slick';
import dangeModeMobileWebp from 'assets/images/danger-mode-mobile.webp';
import dangeModeMobileJpg from 'assets/images/danger-mode-mobile.jpg';
import housekeepingModeMobileWebp from 'assets/images/housekeeping-mode-mobile.webp';
import housekeepingModeMobileJpg from 'assets/images/housekeeping-mode-mobile.jpg';
import quiteModeMobileJpg from 'assets/images/quite-mode-mobile.jpg';
import quiteModeMobileWebp from 'assets/images/quite-mode-mobile.webp';
import quiteMode1 from 'assets/images/quite-mode1.jpg';
import quiteMode1Webp from 'assets/images/quite-mode1.jpg?as=webp';
import housekeepingMode from 'assets/images/housekeeping-mode.jpg';
import housekeepingModeWebp from 'assets/images/housekeeping-mode.jpg?as=webp';
import dangerMode from 'assets/images/danger-mode.jpg';
import dangerModeWebp from 'assets/images/danger-mode.jpg?as=webp';
import LazyLoad from 'react-lazyload';
import { checkWebPSupport } from 'supports-webp-sync';
import loadable from '@loadable/component';
import { useProgressiveImage } from '../../hooks';

const FadeIn = loadable(() => import('../common/FadeIn'));

/**
 * @param setSlider
 * @param sliderSettings
 * @returns {JSX.Element}
 * @constructor
 */
const OptionBlockImageCarousel = ({ setSlider, sliderSettings }) => {
  const isWebPSupport = checkWebPSupport();
  const placeholder1 = isWebPSupport ? quiteMode1Webp : quiteMode1;
  const loaded1 = useProgressiveImage(isWebPSupport ? quiteMode1Webp : quiteMode1);

  const placeholder2 = isWebPSupport ? housekeepingModeWebp : housekeepingMode;
  const loaded2 = useProgressiveImage(isWebPSupport ? housekeepingModeWebp : housekeepingMode);

  const placeholder3 = isWebPSupport ? dangerModeWebp : dangerMode;
  const loaded3 = useProgressiveImage(isWebPSupport ? dangerModeWebp : dangerMode);

  return (
    <Slider
      ref={slider => setSlider(slider)}
      className='option-block__image-carousel'
      {...sliderSettings}
    >
      <div className='option-block__image-slide'>
        <div
          className='option-block__image-bg'
          style={{ backgroundImage: `url(${loaded1 || placeholder1})` }}
        />
      </div>
      <div className='option-block__image-slide'>
        <div
          className='option-block__image-bg'
          style={{ backgroundImage: `url(${loaded2 || placeholder2})` }}
        />
      </div>
      <div className='option-block__image-slide'>
        <div
          className='option-block__image-bg'
          style={{ backgroundImage: `url(${loaded3 || placeholder3})` }}
        />
      </div>
    </Slider>
  );
};

/**
 * @param setSlider
 * @param sliderSettings
 * @returns {JSX.Element}
 * @constructor
 */
const OptionBlockCarousel = ({ setSlider, sliderSettings }) => (
  <Slider
    className='option-block__carousel'
    ref={slider => setSlider(slider)}
    {...sliderSettings}
  >
    <div className='option-block__slide'>
      <div className='container'>
        <figure className='option-block__slide-image'>
          <LazyLoad>
            <FadeIn>
              <picture>
                <source
                  srcSet={quiteModeMobileWebp}
                  type='image/webp'
                />
                <source
                  srcSet={quiteModeMobileJpg}
                  type='image/jpeg'
                />
                <img
                  alt='quiet mode'
                  src={quiteModeMobileJpg}
                />
              </picture>
            </FadeIn>
          </LazyLoad>
        </figure>
        <div className='option-block__slide-content'>
          <h2 className='option-block__slide-title'>Stays quiet in your presence.</h2>
          <div className='option-block__slide-description'>
            <p>
              The first purifier to detect room presence,
              Mila knows to quiet down when you enter the room and deep
              clean when you leave.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className='option-block__slide'>
      <div className='container'>
        <figure className='option-block__slide-image'>
          <LazyLoad>
            <FadeIn>
              <picture>
                <source
                  srcSet={housekeepingModeMobileWebp}
                  type='image/webp'
                />
                <source
                  srcSet={housekeepingModeMobileJpg}
                  type='image/jpg'
                />
                <img
                  src={housekeepingModeMobileJpg}
                  alt='housekeeping mode'
                />
              </picture>
            </FadeIn>
          </LazyLoad>
        </figure>
        <div className='option-block__slide-content'>
          <h2 className='option-block__slide-title'>Works harder when you’re away.</h2>
          <div className='option-block__slide-description'>
            <p>
              Mila is smart enough to do her deepest cleaning when you’re
              not around—so you always come home to a
              clean and quiet space.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className='option-block__slide'>
      <div className='container'>
        <figure className='option-block__slide-image'>
          <LazyLoad>
            <FadeIn>
              <picture>
                <source
                  srcSet={dangeModeMobileWebp}
                  type='image/webp'
                />
                <source
                  srcSet={dangeModeMobileJpg}
                  type='image/jpeg'
                />
                <img
                  alt='housekeeping mode'
                  src={dangeModeMobileJpg}
                />
              </picture>
            </FadeIn>
          </LazyLoad>
        </figure>
        <div className='option-block__slide-content'>
          <h2 className='option-block__slide-title'>Protects what matters most. </h2>
          <div className='option-block__slide-description'>
            <p>
              Mila is the first purifier to include a life-saving
              carbon monoxide sensor to protect against
              malfunctioning furnaces and space heaters.
            </p>
          </div>
        </div>
      </div>
    </div>
  </Slider>
);

/**
 * @returns {JSX.Element}
 * @constructor
 */
const OptionBlock = () => {
  const [ slider1, setSlider1 ] = useState(null);
  const [ slider2, setSlider2 ] = useState(null);

  const goToFirst = () => {
    slider1?.slickGoTo(0);
  };

  const beforeChange = (current, next) => {
    if (next === current) {
      setTimeout(() => {
        goToFirst();
      }, 6000);
    }
  };

  const slider1Settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 6000,
    pauseOnHover: false,
    pauseOnFocus: false,
    infinite: false,
    cssEase: 'ease-in-out',
    asNavFor: slider2,
  };

  const slider2Settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    autoplay: true,
    cssEase: 'ease-in-out',
    autoplaySpeed: 6000,
    pauseOnHover: false,
    pauseOnFocus: false,
    asNavFor: slider1,
    beforeChange: (_oldIndex, newIndex) => { beforeChange(2, newIndex); },
  };

  return (
    <section className='option-block'>
      <div className='option-block__intro'>
        <h2 className='option-block__title'>Mila always puts you first.</h2>
        <div className='option-block__description'>
          <p>Finally, an air purifier designed with the wants and needs of real people in mind.</p>
        </div>
      </div>
      <OptionBlockImageCarousel setSlider={setSlider1} sliderSettings={slider1Settings} />
      <OptionBlockCarousel setSlider={setSlider2} sliderSettings={slider2Settings} />
    </section>
  );
};

export default OptionBlock;
